import { Link } from "gatsby";
import React from "react";
import Logo from "../images/logo-transparent.png";

function Footer() {

  return (
    <footer>
      <div className="bg-blue-50 mt-12 text-base leading-6 text-gray-700">
        <div className="max-w-screen-xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 xl:mt-0">
            <div>
              <img
                src={Logo}
                alt="Evonergy SARL – Entreprise d'électricité et de climatisation"
                className="h-32"
              />
              <p className="text-base leading-6 text-gray-700 mt-4">
                Evonergy SARL – Entreprise d'électricité et de climatisation
                <br />
                3 rue Paul Rocache
                <br />
                31100 Toulouse
              </p>
              <p className="mt-4 text-base leading-6 text-gray-700">
                <svg
                  className="h-6 w-6 pr-1 inline-block"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.04 14.69l1.07-2.14a1 1 0 011.2-.5l6 2A1 1 0 0122 15v5a2 2 0 01-2 2h-2A16 16 0 012 6V4c0-1.1.9-2 2-2h5a1 1 0 01.95.68l2 6a1 1 0 01-.5 1.21L9.3 10.96a10.05 10.05 0 003.73 3.73zM8.28 4H4v2a14 14 0 0014 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 01-1.3.46 12.04 12.04 0 01-6.02-6.01 1 1 0 01.46-1.3l2.26-1.14L8.28 4z" />
                </svg>
                <a href="tel:0033582952867" title="Appelez nous">
                  05 82 95 28 67
                </a>
              </p>
            </div>
            <div className="mt-4 md:mt-0">
              <span className="text-sm leading-5 font-bold tracking-wider text-gray-900 uppercase">
                Nos prestations
              </span>
              <ul>
                {[
                  {
                    name: `Diagnostic électrique`,
                  },
                  {
                    name: `Électricité générale : courant faible et courant fort`,
                  },
                  {
                    name: `Installation électrique`,
                  },
                  {
                    name: `Dépannage électrique`,
                  },
                  {
                    name: `Remise aux normes électriques`,
                  },
                  {
                    name: `Climatisation : vente, installation et entretien`,
                  },
                  {
                    name: `Installation et entretien VMC`,
                  },
                  {
                    name: `Chauffage électrique : installation, entretien et dépannage`,
                  },
                ].map((item) => (
                  <li
                    key={item.name}
                    className="mt-4 text-base leading-6 text-gray-700"
                  >
                    <svg
                      className="h-6 w-6 pr-1 inline-block"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7"></path>
                    </svg>
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-4 md:mt-0">
              <span className="text-sm leading-5 font-bold tracking-wider text-gray-900 uppercase">
                Evonergy
              </span>
              <ul>
                {[
                  {
                    url: `/zones-d-interventions-electricite-climatisation/`,
                    name: `Zone d'intervention`,
                  },
                  {
                    url: `/qui-sommes-nous/`,
                    name: `Notre entreprise`,
                  },
                  {
                    url: `/mentions-legales/`,
                    name: `Mentions légales`,
                  },
                  {
                    url: `/contactez-nous/`,
                    name: `Contactez nous`,
                  },
                ].map((link) => (
                  <li key={link.name} className="mt-4">
                    <Link
                      className="text-base leading-6 text-gray-700"
                      to={link.url}
                      title={link.name}
                    >
                      <svg
                        className="h-6 w-6 pr-1 inline-block"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M5 13l4 4L19 7"></path>
                      </svg>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base leading-6 text-gray-700 text-center">
              &copy; 2020 Evonergy SARL. Tous droits réservés. Réalisation:{" "}
              <a
                href="https://www.timetobefirst.com"
                rel="noopener noreferrer"
                target="_blank"
                className="text-sm leading-6 text-blue-700 hover:text-blue-900"
              >
                Agence Time To Be First
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;